@font-face {
  font-family: 'thanh-tra';
  src:  url('../../fonts/icons/thanh-tra.eot?xoibdd');
  src:  url('../../fonts/icons/thanh-tra.eot?xoibdd#iefix') format('embedded-opentype'),
    url('../../fonts/icons/thanh-tra.ttf?xoibdd') format('truetype'),
    url('../../fonts/icons/thanh-tra.woff?xoibdd') format('woff'),
    url('../../fonts/icons/thanh-tra.svg?xoibdd#thanh-tra') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="itt-"], [class*=" itt-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'thanh-tra' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.itt-check-bold:before {
  content: "\e92c";
}
.itt-lock:before {
  content: "\e929";
}
.itt-poweroff:before {
  content: "\e92a";
}
.itt-check:before {
  content: "\e92b";
}
.itt-building:before {
  content: "\e928";
}
.itt-pay-back:before {
  content: "\e926";
}
.itt-sort:before {
  content: "\e927";
}
.itt-phone:before {
  content: "\e925";
}
.itt-close-circle:before {
  content: "\e924";
}
.itt-sending:before {
  content: "\e920";
}
.itt-stopwatch:before {
  content: "\e921";
}
.itt-user-reload:before {
  content: "\e922";
}
.itt-write:before {
  content: "\e923";
}
.itt-continue:before {
  content: "\e91e";
}
.itt-back:before {
  content: "\e91f";
}
.itt-link:before {
  content: "\e91d";
}
.itt-recycle-bin:before {
  content: "\e91c";
}
.itt-information:before {
  content: "\e91b";
}
.itt-plus-circle:before {
  content: "\e900";
}
.itt-close:before {
  content: "\e901";
}
.itt-contract:before {
  content: "\e902";
}
.itt-save:before {
  content: "\e903";
}
.itt-down-arrow:before {
  content: "\e908";
}
.itt-download:before {
  content: "\e909";
}
.itt-filter-result:before {
  content: "\e90a";
}
.itt-group:before {
  content: "\e90b";
}
.itt-arrow-back:before {
  content: "\e90c";
}
.itt-magnifying-glass:before {
  content: "\e910";
}
.itt-pencil:before {
  content: "\e911";
}
.itt-plus:before {
  content: "\e912";
}
.itt-printer:before {
  content: "\e914";
}
.itt-file-setting:before {
  content: "\e915";
}
.itt-setting:before {
  content: "\e916";
}
.itt-upload:before {
  content: "\e917";
}
.itt-search:before {
  content: "\e918";
}
.itt-writing:before {
  content: "\e919";
}
.itt-document:before {
  content: "\e91a";
}
.itt-trigger:before {
  content: "\e913";
}
.itt-home:before {
  content: "\e906";
}
.itt-user-guide:before {
  content: "\e907";
}
.itt-pie-chart:before {
  content: "\e90d";
}
.itt-file-search:before {
  content: "\e90e";
}
.itt-file:before {
  content: "\e90f";
}
.itt-redirect:before {
  content: "\e905";
}
.itt-pending:before {
  content: "\e904";
}