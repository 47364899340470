.site-layout {
  background: #F5F5F5;
  min-height: 100vh;

  .header {
    background: $primary-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 18px 18px 18px 0;
    height: 60px;

    .header-left {
      display: flex;
      align-items: center;
      height: 100%;

      &__trigger {
        color: #fff;
        margin-right: 6px;
        cursor: pointer;
        padding: 0 18px;
      }

      &__title {
        font-family: 'Roboto-bold';
        font-style: normal;
        text-transform: uppercase;
        color: #fff;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media screen and (max-width: 1124px) {
          display: none;
        }
      }
    }

    .header-right {
      display: flex;
      align-items: center;
      height: 24px;
      justify-content: center;
      font-family: $text-medium;

      .header-auth {
        &__fullname {
          margin: 0 8px;
          font-family: $text-medium;
          color: #fff;
          font-size: 14px;
          line-height: 24px;
          white-space: nowrap;
        }

        &__icon {
          color: #fff;
          font-family: $text-medium;
        }
      }
    }
  }
}

.dropdown-content {
  .ant-dropdown-menu-title-content {
    font-family: $text-regular;
  }
}

.ant-layout-sider.sidebar {
  background-color: #fff;
  width: 30px;

  .logo {
    background-color: $primary-color;
    padding: 10px 0 10px 20px;
    height: 60px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding: 7px;
    }

    &__title {
      display: inline-block;
      font-family: $text-regular;
      font-size: 16px;
      text-transform: uppercase;
      color: #fff;
      margin: 0;
      margin-left: 10px;
    }
  }

  .menu {
    height: calc(100% - 60px);
    font-family: $text-medium;
    font-size: 14px;

    .ant-menu-sub {
      background-color: #fff;
      font-size: 13px;

      .ant-menu-item-only-child {
        line-height: 14px;
        white-space: break-spaces;
      }
    }
  }
}
.ant-menu-inline.ant-menu-root .ant-menu-item>.ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title>.ant-menu-title-content {
  white-space: break-spaces;
  line-height: 15px;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item, .ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
  height: 50px;
}

.layout-content {
  margin: 0;
  padding: 16px;
  min-height: 280px;
  overflow: auto;
}

.layout-content-footer {
  position: relative;
  height: 100%
}

.page-header {
  font-family: $heading-font;
  font-size: 15px;
  padding: 0;
  margin-bottom: 8px;

  .ant-page-header-heading-left {
    margin: 0;
    text-transform: uppercase;

    .anticon {
      margin-bottom: 3px;
      font-size: 15px;
    }

    .ant-page-header-heading-title {
      font-size: 18px;
      line-height: 26px;
      color: #19282F;
      @media screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
}